@font-face {
  font-family: imminent;
  src: url(RebootCrush.ttf);
}
h1 {
  font-family: imminent !important;
}
.Immiment {
  margin-top: 120px;
}
body {
  background-color: #333;
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  /* background-image: url(273161273_696433308306531_2535808988844369459_n.png); */
  background-position: 100 -100;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  /* background: rgb(49, 49, 177);
  background: linear-gradient(
    0deg,
    rgba(49, 49, 177, 1) 0%,
    rgba(49, 49, 177, 1) 50%,
    rgba(49, 49, 177, 0.6) 100%
  ); */
}

.container {
  width: 100%;
  margin: auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}
